<template lang="pug">
.terms-of-use
  h1 Termos e Condições
  span.subtitle
    | Sumário do&nbsp;
    NuxtLink.contract-link(
      v-bind:class="{ active: route.name == 'contract' }",
      to="/contrato-de-assinatura"
    ) Contrato de Adesão
    | &nbsp;da Magenta

  h2 A Magenta
  p A Magenta é um clube de assinatura de produtos de beleza e cuidados pessoais que entrega em seu endereço, uma vez por mês, uma seleção com cinco produtos full-size especialmente escolhidos para você.

  h2 Assinatura
  p A assinatura pode ser feita em 3 (três) planos com períodos diferentes: Mensal, Semestral ou Anual.

  h2 Valores
  ul
    li Mensal: R$169,90 mais frete, renovado mensalmente podendo ser cancelado a qualquer momento sem multa;
    li Semestral: 6x R$159,90 (R$959,40) mais frete de 6 (seis) meses, renovado a cada 6 (seis) meses;
    li Anual: 12x R$149,90 (R$1.798,80) mais frete de 12 (doze) meses, renovado a cada 12 (doze) meses.

  h2 Parcelamento
  p Assinaturas com pagamentos realizados por Cartão de Crédito são automaticamente parceladas de acordo com o plano escolhido:
  ul
    li Mensal: Não há parcelamento;
    li Semestral: Parcelado em 6 (seis) vezes, sem juros;
    li Anual: Parcelado em 12 (doze) vezes, sem juros.
  p Assinaturas com pagamentos realizados por Boleto Bancário não têm parcelamento.

  h2 Recorrência
  p Enquanto ativas, as assinaturas se renovam automaticamente de acordo com o período de cada plano.

  h2 Cancelamento
  p O cancelamento pode ser feito pelo site ou por pedido pelo e-mail contato@boxmagenta.com.br.
  p Não existe taxa de cancelamento e nem permanência mínima para o plano mensal. Nos planos trimestrais, semestrais e anuais será cobrada uma multa de 50% pro rata ao restante do período contratado.

  h2 Frete
  p O frete é pago pelo assinante, e cabe à Magenta escolher o meio mais indicado para que os kits cheguem de forma segura e rápida ao endereço.

  h2 Similaridade das Seleções
  p Como a seleção dos kits é feita baseado no perfil de cada assinante, não há obrigatoriedade de igualdade absoluta entre kits enviados a assinantes distintos. Dessa forma, por quaisquer motivos, os kits podem ser diferentes entre si.

  h2 Prazos
  p O prazo estimado de entrega é informado no ato da assinatura e começa a ser contado a partir da confirmação do pagamento.

  h2 Brindes
  p É reservado o direito à Magenta de enviar, junto à seleção, brindes variados, que não obrigarão a repetição mensal. Isto é, a Magenta poderá, não obrigatoriamente, enviar brindes periodicamente.

  h2 Trocas
  p Reservamos o direito de não efetuar trocas, salvo casos de produtos danificados no transporte ou com defeito de fabricação.
</template>
<script setup lang="ts">
const route = useRoute()
</script>
<style lang="sass" scoped>
@import "~/assets/styles/variables"

.terms-of-use
  text-align: justify
  max-width: 960px
  margin: 0 auto
  padding: 0 14px

  h1
    text-align: center
    margin-bottom: 0

  span.subtitle
    display: block
    text-align: center

  p

    font-size: 14px
    text-indent: 2em

  .contract-link
    color: $magenta
</style>
